import { NavBar } from "./NavBar";
import vijay from "../assets/images/vj.png";
import vj_background from "../assets/images/vj_background.jpg";

export const LandingPage = () => {
  return (
    <>
      <NavBar />
      <div className="landingpage">
        <img src={vj_background} className="vj_img" alt=""></img>

        <div className="page-container">
          <div className="caption">
            <h1>
              <span className="header-name">T</span>halapathy{" "}
              <span className="header-name">V</span>ijay{" "}
            </h1>
          </div>
          <img src={vijay} className="img" alt=""></img>
        </div>
      </div>
    </>
  );
};
