export const Contact = () => {
  return(
    <div>
        <h1>Contact Us</h1>
        <h2>VMI</h2>
        <h3>Ellapuram Onriyam,</h3>
        <h3>Thiruvallur Dist,</h3>
        <h3>Tamil Nadu.</h3>
    </div>
  )
}