import { LandingPage } from "./LandingPage";
import { BodyPage } from "./BodyPage";
import { Footer } from "./Footer";

export const HomePage = () => {
  return (
    <>
      <LandingPage />
      <BodyPage />
      <Footer />
    </>
  );
};
