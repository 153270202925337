import { Link } from "react-router-dom";
import logo from "../assets/images/vmi.png";

export const NavBar = () => {
  return (
    <div className="nav-container">
      <div className="nav-header">
        <img src={logo} alt="vjimg" />
        <h1>
          <span className="header-name">V</span>ijay{" "}
          <span className="header-name">M</span>akkal{" "}
          <span className="header-name">I</span>yyakkam
        </h1>
      </div>
      <div className="nav-menu">
        <Link to="/" className="menu">
          Home
        </Link>
        <Link to="/" className="menu">
          Welfare
        </Link>
        <Link to="/" className="menu">
          Vijay
        </Link>
        <Link to="/" className="menu">
          About us
        </Link>
      </div>
    </div>
  );
};
