import { Contact } from "./Contact"
import Vmi_logo from "../assets/images/vmi_logo.png";

export const BodyPage = () => {
  return (
    <div className="body-content">
      <div id="Vmi_Intro_section">
        <h1>Vijay Makkal Iyyakkam</h1>
        <div id="Vmi_Intro">
          <img src={Vmi_logo} alt="" />
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
              officiis eius doloribus cumque, reiciendis fugit temporibus rerum
              ut aperiam. Corporis impedit nobis reiciendis commodi perspiciatis
              harum nihil rerum ea expedita!
            </p>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};
